import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import action
import { faqCategoryEdit } from "../../actions/faqActions";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import { editPair } from "../../actions/walletAction";
import { decryptData, encryptData } from "../../utils/common_function";

const initialFormValue = {
  id: "",
  firstCurrencySymbol: "",
  secondCurrencySymbol: "",
  botstatus: "",
  firstCurrency: "",
  secondCurrency: "",
  status: ""
};

class EditSpotPair extends React.Component {
  constructor() {
    super();
    this.state = {
      formValue: initialFormValue,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { record } = nextProps;
    if (record) {
      const formState = {
        formValue: {
          id: record._id,
          // name: record.name,
          status: record.status,
          firstCurrencySymbol: record.firstCurrencySymbol,
          secondCurrencySymbol: record.secondCurrencySymbol,
          botstatus: record.botstatus,
          minPrice: record.minPricePercentage,
          maxPrice: record.maxPricePercentage,
          minQuantity: record.minQuantity,
          maxQuantity: record.maxQuantity,
          makerFee: record.maker_rebate,
          takerFee: record.taker_fees,
          marketPrice: record.markPrice,
          currencyDecimal: record.secondFloatDigit,
          baseCurrencyDecimal: record.firstFloatDigit,
          low: record.low,
          high: record.high,
          last: record.last,
          firstVolume: record.firstVolume,
          secondVolume: record.secondVolume,
          change: record.change,
          changePrice: record.change,
          status: record.status,
          pipSize: record.pip_size,
          // apiUrl: record?.priceUrl,
          // apiKey: decryptData(record?.apiKey),
          isStable: record?.isStable,
        },
      }
      // if (record?.apiKey) {
      //   formState.formValue.apiKey = decryptData(record?.apiKey)
      // }
      this.setState(formState);
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
    this.setState({ errors: "" });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: this?.props?.record, errors: {} });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { fetchData } = this.props;
      const { formValue } = this.state;
      let reqData = formValue;
      const payload = {
        firstCurrencySymbol: formValue.firstCurrencySymbol ? formValue.firstCurrencySymbol : this.props?.record?.firstCurrencySymbol,
        secondCurrencySymbol: formValue.secondCurrencySymbol ? formValue.secondCurrencySymbol : this.props?.record?.secondCurrencySymbol,
        botstatus: formValue.botstatus ? formValue.botstatus : this.props?.record?.botstatus,
        type: "spot",
        status: formValue.status ? formValue.status : this.props?.record?.status,
        tikerRoot: formValue.firstCurrencySymbol + formValue.secondCurrencySymbol,
        minPricePercentage: formValue.minPrice,
        maxPricePercentage: formValue.maxPrice,
        minQuantity: formValue.minQuantity,
        maxQuantity: formValue.maxQuantity,
        maker_rebate: formValue.makerFee,
        taker_fees: formValue.takerFee,
        last: formValue.last,
        secondFloatDigit: Number(formValue.currencyDecimal),
        firstFloatDigit: formValue.baseCurrencyDecimal,
        markPrice: formValue.marketPrice,
        priceUrl: formValue?.apiUrl,
        isStable: formValue?.isStable
      }
      if (formValue?.apiKey) {
        payload.apiKey = encryptData(formValue?.apiKey)
      }
      payload.tikerRoot = payload.firstCurrencySymbol + payload.secondCurrencySymbol

      if (!payload.firstCurrencySymbol) {
        return toastAlert("error", "Please select a Base Currency.", "addFaqCategory");
      }
      if (!payload.secondCurrencySymbol) {
        return toastAlert("error", "Please select a Quote Currency.", "addFaqCategory");
      }
      if (!payload.botstatus) {
        return toastAlert("error", "Please select a Bot status.", "addFaqCategory");
      }
      if (payload.firstCurrencySymbol === "Select") {
        return toastAlert("error", "Please select a may not empty.", "addFaqCategory");
      }
      if (payload.secondCurrencySymbol === "Select") {
        return toastAlert("error", "Quote currency may not empty.", "addFaqCategory");
      }
      if (payload.secondCurrencySymbol === "Select") {
        return toastAlert("error", "Quote currency may not empty.", "addFaqCategory");
      }
      if (payload.firstCurrencySymbol === payload.secondCurrencySymbol) {
        return toastAlert("error", "Base and Quote are not allowed as same.", "addFaqCategory");
      }
      if (!payload.firstFloatDigit) {
        return toastAlert("error", "Base currency decimal may not be empty.", "addFaqCategory");
      }
      if (!payload.secondFloatDigit) {
        return toastAlert("error", "Quote currency decimal may not be empty.", "addFaqCategory");
      }
      if (!payload.maker_rebate) {
        return toastAlert("error", "Maker fee may not be empty.", "addFaqCategory");
      }
      if (!payload.taker_fees) {
        return toastAlert("error", "Taker fee may not be empty", "addFaqCategory");
      }

      if (!payload.last) {
        return toastAlert("error", "Last price may not be empty.", "addFaqCategory");
      }
      if (!payload.minPricePercentage) {
        return toastAlert("error", "Minimum price may not be empty.", "addFaqCategory");
      }
      if (!payload.maxPricePercentage) {
        return toastAlert("error", "Maximum price may not be empty.", "addFaqCategory");
      }
      if (Number(payload.minQuantity) >= Number(payload.maxQuantity)) {
        return toastAlert("error", "Minimum quantity must be lesser than maximum quanity.", "addFaqCategory");
      }
      if (!payload.last) {
        return toastAlert("error", "Last price may not be empty.", "addFaqCategory");
      }
      if (Number(payload.minPricePercentage) <= 0 || Number(payload.minPricePercentage) > 100 || !Number(payload.minPricePercentage)) {
        return toastAlert("error", "Minimum price percentage between 1 to 100.", "addFaqCategory");
      }
      if (Number(payload.maxPricePercentage) <= 0 || Number(payload.maxPricePercentage) > 100 || !Number(payload.maxPricePercentage)) {
        return toastAlert("error", "Maximum price percentage between 1 to 100.", "addFaqCategory");
      }
      if (!payload.markPrice) {
        return toastAlert("error", "Market price may not be empty.", "addFaqCategory");
      }
      const result = await editPair(this.props?.record?._id, payload);
      if (result && result.result && result.result.data && result.result.data.statusCode && result.result.data.statusCode === 200) {
        return toastAlert("error", result.result.data.message, "addFaqCategory");
      }
      window.location.reload()
    } catch (err) { }
  };

  render() {
    const { errors } = this.state;
    let { firstCurrencySymbol, secondCurrencySymbol, botstatus, status, currencyDecimal, baseCurrencyDecimal, makerFee, takerFee,
      minPrice, maxPrice, minQuantity, maxQuantity, marketPrice, pipSize, firstVolume, secondVolume, changePrice, change, markupPercentage,
      low, high, last, apiUrl, apiKey, isStable } = this.state.formValue;
    const { isShow, records, record, fullList } = this.props;
    // currencyDecimal=record?.secondFloatDigit
    // this.state = {
    //   formValue: record,
    //   // errors: {},
    // };
    if (record?.isStable) {
      records.push({ symbol: record?.firstCurrencySymbol })
    }
    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="md"
          centered
        // scrollable={true}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Edit Spot Pair</h4>
          </Modal.Header>
          <Modal.Body className="derivative__modalBody">
            <form noValidate onSubmit={this.handleSubmit} id="add-faq">
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name" className="addingPair__cmnLabel">Base Currency</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    as="select"
                    custom
                    name={"firstCurrencySymbol"}
                    value={firstCurrencySymbol ? firstCurrencySymbol : record?.firstCurrencySymbol}
                    onChange={this.handleChange} disabled={true}>
                    {
                      records && records.length && records.map((item) => {
                        return (<option value={item.symbol} >{item.symbol}</option>)
                      })
                    }
                  </Form.Control>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name" className="addingPair__cmnLabel">Quote Currency</label>
                </div>
                <div className="col-md-9">
                  <Form.Control
                    as="select"
                    custom
                    name={"secondCurrencySymbol"}
                    value={secondCurrencySymbol ? `${secondCurrencySymbol}_${isStable ? isStable : false}` : `${record?.symbol}_${record?.isStable ? record?.isStable : false}`}
                    // value={secondCurrencySymbol ? secondCurrencySymbol : record?.symbol}
                    // value={secondCurrencySymbol}
                    onChange={this.handleChange}
                    disabled={true}
                  >{
                      fullList && fullList.length && fullList.map((item) => {
                        return (<option value={`${item.symbol}_${item.isStable ? item.isStable : false}`}>{item.symbol}</option>)
                      })
                    }
                    {/* <option value={"active"}>Active</option>
                    <option value={"Inactive"}>Inactive</option> */}
                  </Form.Control>
                  {/* <span className="text-danger">{errors.name}</span> */}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Bot Status</label>
                </div>

                <div className="col-md-9">
                  <Form.Control
                    as="select"
                    custom
                    name={"botstatus"}
                    value={botstatus}
                    onChange={this.handleChange}
                  >
                    <option value={"binance"}>binance</option>
                    <option value={"off"}>off</option>
                  </Form.Control>
                  {/* <span className="text-danger">{errors.name}</span> */}
                </div>
              </div>
              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Bot Status</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={botstatus}
                    name="name"
                    type="text"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
                  </div> */}
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Base Currency Decimal</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={baseCurrencyDecimal}
                    name={"baseCurrencyDecimal"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Quote Currency Decimal</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={currencyDecimal}
                    name={"currencyDecimal"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Maker Fee(%)</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={makerFee}
                    name={"makerFee"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Taker Fee(%)</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={takerFee}
                    name={"takerFee"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Low</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={low}
                    name={"low"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div> */}
              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">High</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={high}
                    name={"high"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div> */}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Minimum Price(%)</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={minPrice}
                    name={"minPrice"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Maximum Price(%)</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={maxPrice}
                    name={"maxPrice"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Minimum Quantity</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={minQuantity}
                    name={"minQuantity"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Maximum Quantity</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={maxQuantity}
                    name={"maxQuantity"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Market Price</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={marketPrice}
                    name={"marketPrice"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Last price</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={last}
                    name={"last"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>

              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Pip Size</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={pipSize}
                    name={"pipSize"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">First Volume</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={firstVolume}
                    name={"firstVolume"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Second Volume</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={secondVolume}
                    name={"secondVolume"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Change</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={change}
                    name={"change"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="question" className="addingPair__cmnLabel">Change Price</label>
                </div>
                <div className="col-md-9">
                  <input
                    onChange={this.handleChange}
                    value={changePrice}
                    name={"changePrice"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div> */}
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Status</label>
                </div>

                <div className="col-md-9">
                  <Form.Control
                    as="select"
                    custom
                    name={"status"}
                    value={status ? status : record?.status}
                    onChange={this.handleChange}
                  >
                    <option value={"active"}>Active</option>
                    <option value={"Inactive"}>Inactive</option>
                  </Form.Control>
                  {/* <span className="text-danger">{errors.name}</span> */}
                </div>
              </div>

              {/* <div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="question" className="addingPair__cmnLabel">Price API URL</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      onChange={this.handleChange}
                      value={apiUrl}
                      name={"apiUrl"}
                      type="text"
                      error={errors.name}
                      className={classnames("form-control input__borderColor", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="question" className="addingPair__cmnLabel">API key</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      onChange={this.handleChange}
                      value={apiKey}
                      name={"apiKey"}
                      type="password"
                      error={errors.name}
                      className={classnames("form-control input__borderColor", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>
              </div> */}

            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button
              onClick={this.handleSubmit}
              type="submit"
              className="btn btn-primary"
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default EditSpotPair;
