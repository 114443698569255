// import package
import React, { Component } from 'react';
import { Card, Button } from "react-bootstrap";
import classnames from "classnames";
import { AddDemoVideoLink,GetDemoVideoLink } from '../../actions/P2PCreateaction';
import { toastAlert } from '../../lib/toastAlert';
// import { encryptString } from '../../../../Backend/lib/cryptoJS';


// import action

//import lib

class DemoVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            link:"",
            privatekey : "" , 
            walletaddress : "",
            id:"",
            type:false
        }
        
    }
    componentDidMount(){
        this.fetchdata();
    }

    async fetchdata() {
        var result = await GetDemoVideoLink();
        this.setState({ type: result?.data?.data?.market, id: result?.data?.data?._id });
    }

    async handleSubmit() {
        const payload = {
            market: this?.state?.type,
        }
        // if (!payload?.market) {
        //     return toastAlert("error", "Link is required.");
        // }
        const result = await AddDemoVideoLink(this?.state.id, payload);
        this.fetchdata();
        if (result?.status === 200) {
            toastAlert("success", "Updated successfully!..");
        }
    }
    handleCheckboxChange = (event) => {
        this.setState({ type: event.target.checked });
    };


    render() {
        const { errors } = this.state;

        return (
            <Card>
                <Card.Header><p className="text-white mb-0"><b>Trade Type</b></p></Card.Header>
                <Card.Body>
                    <div className="row mt-2 align-items-center">
                        <div className="col-md-3">
                            <label htmlFor="currencyName">Market</label>
                        </div>
                        <div className="col-md-9 checkm">
                            <input name="checkBox" type="checkbox" className="form-check-input" checked={this.state.type} onChange={this.handleCheckboxChange} />

                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Button onClick={() =>this.handleSubmit()}>Submit</Button>
                </Card.Footer>
            </Card>
        )
    }
}

export default DemoVideo;