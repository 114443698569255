// import config
import { cibRollupJs } from '@coreui/icons';
import axios from '../config/axios';
import * as XLSX from 'xlsx';
export const getDepositList = async (data) => {
    try {
        const respData = await axios({
            'url': `/api/flexiBank/getDeposit`,
            'method': 'get',
            'params': data
        })

        if (data.export == 'csv') {
            let csvContent = "data:text/csv;charset=utf-8,";
            respData.data.forEach(row => {
                row[0] = row[0].replace(",", ":")
                csvContent += `${row.toString()}\n`;
            });
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", 'deposit list.csv');
            document.body.appendChild(link);
            link.click();
        }

        if (data.export == 'xls') {
            let jsonData = respData.data;
            console.log("entry for deposit", jsonData)
            const [date, userId, toAddress, coin, paymentType, transferAmount, status] = jsonData[0];
            console.log(date, userId, toAddress, coin, paymentType, transferAmount, status, "params", jsonData[0].length)
            jsonData = jsonData.map((item) => {
                return { [date]: item[0], [userId]: item[1], [toAddress]: item[2], [coin]: item[3], paymentType: item[4], transferAmount: item[5], status: item[6] }
            })
            jsonData.shift();
            const worksheet = XLSX.utils.json_to_sheet(jsonData);
            console.log(jsonData.length, "after shift", jsonData[0])
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['deposit list'] };
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'deposit list.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // const url = window.URL.createObjectURL(new Blob([respData.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'Deposit List.xls');
            // document.body.appendChild(link);
            // link.click();
        }

        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}

export const getWithdrawList = async (data) => {
    try {
        const respData = await axios({
            'url': `/api/flexiBank/getDeposit`,
            'method': 'get',
            'params': data
        })

        if (data.export == 'csv') {
            let csvContent = "data:text/csv;charset=utf-8,";
            respData.data.forEach(row => {
                csvContent += `${row.toString()}\n`;
            });
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", 'withdrawList.csv');
            document.body.appendChild(link);
            link.click();
        }

        if (data.export == 'xls') {
            let jsonData = respData.data;
            const [date, userId, toAddress, coin, paymentType, transferAmount, status] = jsonData[0];
            jsonData.shift();
            console.log(date, userId, toAddress, coin, paymentType, transferAmount, status, "header for excel frile headers for excel file")
            jsonData = jsonData.map((item) => {
                return {
                    // [date]: item[0].replace(",",":"),
                    [userId]: item[1],
                    [toAddress]: item[2],
                    [coin]: item[3],
                    [paymentType]: item[4],
                    [transferAmount]: item[5],
                    [status]: item[6]
                }
            })
            console.log(jsonData, "jsonDatajsonDatajsonDatajsonData")
            const worksheet = XLSX.utils.json_to_sheet(jsonData);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['withdraw list'] };
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'withdraw List.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // const url = window.URL.createObjectURL(new Blob([respData.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'Withdraw List.xls');
            // document.body.appendChild(link);
            // link.click();
        }

        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}

export const approveCoinWithdraw = async (id) => {
    try {
        const respData = await axios({
            'url': `/adminapi/coinWithdraw/approve/${id}`,
            'method': 'get',
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const rejectCoinWithdraw = async (id) => {
    try {
        const respData = await axios({
            'url': `/adminapi/coinWithdraw/reject/${id}`,
            'method': 'get',
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const addTourHandler = async (data) => {
    try {
        console.log(data,"datatatatat")
        const respData = await axios({
            'url': `/api/future/addTour`,
            'method': 'post',
            data: data
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const updateTourHandler = async (id, data) => {
    try {
        console.log(data, "datatatatat")
        const respData = await axios({
            'url': `/api/future/updateTour/${id}`,
            'method': 'put',
            data: data
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const getTours = async () => {
    try {
        const respData = await axios({
            'url': `/api/future/getTours`,
            'method': 'get',
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            data:respData.data.data
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const approveFiatWithdraw = async (id) => {
    try {
        const respData = await axios({
            'url': `/adminapi/fiatWithdraw/approve/${id}`,
            'method': 'get',
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const rejectFiatWithdraw = async (id) => {
    try {
        const respData = await axios({
            'url': `/adminapi/fiatWithdraw/reject/${id}`,
            'method': 'get',
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const approveFiatDeposit = async (data) => {
    try {
        const respData = await axios({
            'url': `/adminapi/fiatDeposit/approve`,
            'method': 'post',
            'data': data
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        }
    }
}

export const fundList = async (data) => {
    try {
        const respData = await axios({
            'url': `/adminapi/fundList`,
            'method': 'get',
            'params': data
        })
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}

export const getPairLists = async (data, type, exportType) => {
    try {
        let url = `/pair?type=${type}`
        if (!exportType) {
            url = `/pair?type=${type}&page=${data.page}&limit=${data.limit}`
        }
        const respData = await axios({
            'url': url,
            'method': 'get',
        })
        console.log(data, "queryData")
        if (data.export == 'csv') {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += type && type === "spot" ? "Base Currency,Quote Currency,Bot Status,Status\n" : "Base Currency,Quote Currency,Status\n";
            respData.data.data.pair.forEach(row => {
                csvContent += type && type === "spot" ? `${row.firstCurrencySymbol},${row.secondCurrencySymbol},${row.botstatus},${row.status}\n` : `${row.firstCurrencySymbol},${row.secondCurrencySymbol},${row.status}\n`;
            });
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", type && type === 'spot' ? 'Spot_pair.csv' : 'Derivative_pair.csv');
            document.body.appendChild(link);
            link.click();
        }

        if (data.export == 'xls') {
            let jsonData = respData.data.data.pair;
            jsonData = jsonData.map((item) => {
                if (type && type === "spot") {
                    return { "Base Currency": item.firstCurrencySymbol, "Quote Currency": item.secondCurrencySymbol, "Bot Status": item.botstatus, "Status": item.status }
                } else {
                    return { "Base Currency": item.firstCurrencySymbol, "Quote Currency": item.secondCurrencySymbol, "Status": item.status }
                }
            })
            const worksheet = XLSX.utils.json_to_sheet(jsonData);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', type && type === 'spot' ? 'Spot_pair.xlsx' : 'Derivative_pair.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        return {
            status: "success",
            loading: false,
            result: respData?.data?.data
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}
export const getFuturePairLists = async (data, type, exportType) => {
    try {
        let url = `/pair?type=${type}`
        if (!exportType) {
            url = `/pair?type=${type}&page=${data.page}&limit=${data.limit}`
        }
        const respData = await axios({
            'url': url,
            'method': 'get',
        })
        if (data.export == 'csv') {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += type && type === "spot" ? "Base Currency,Quote Currency,Bot Status,Status\n" : "Base Currency,Quote Currency,Status\n";
            respData.data.data.pair.forEach(row => {
                csvContent += type && type === "spot" ? `${row.firstCurrencySymbol},${row.secondCurrencySymbol},${row.botstatus},${row.status}\n` : `${row.firstCurrencySymbol},${row.secondCurrencySymbol},${row.status}\n`;
            });
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", type && type === 'spot' ? 'spot_pair.csv' : 'Future_pair.csv');
            document.body.appendChild(link);
            link.click();
        }

        if (data.export == 'xls') {
            let jsonData = respData.data.data.pair;
            jsonData = jsonData.map((item) => {
                if (type && type === "spot") {
                    return { "Base Currency": item.firstCurrencySymbol, "Quote Currency": item.secondCurrencySymbol, "Bot Status": item.botstatus, "Status": item.status }
                } else {
                    return { "Base Currency": item.firstCurrencySymbol, "Quote Currency": item.secondCurrencySymbol, "Status": item.status }
                }
            })
            const worksheet = XLSX.utils.json_to_sheet(jsonData);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', type && type === 'spot' ? 'spot_pair.xlsx' : 'Future_pair.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        return {
            status: "success",
            loading: false,
            result: respData?.data?.data
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}

export const addPair = async (data) => {
    try {
        const respData = await axios({
            'url': `/pair`,
            'method': 'post',
            'data': data
        })
        console.log(respData, "respData")
        return {
            status: "success",
            loading: false,
            result: respData
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const editPair = async (id, data) => {
    try {
        const respData = await axios({
            'url': `/pair/${id}`,
            'method': 'put',
            'data': data
        })
        console.log(respData, "respData")
        return {
            status: "success",
            loading: false,
            result: respData
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
        }
    }
}