import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";

// import component
import CoinDepositModal from "../partials/CoinDepositModal";
import FiatDepositModal from "../partials/FiatDepositModal";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { getPairLists } from "../../actions/walletAction";

// impport lib
import { paymentType } from "../../lib/displayStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

//import downloads
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import AddSpotPair from "../partials/AddSpotPair";
import EditSpotPair from "../partials/EditSpotPair";
import { getCurrency } from "../../actions/currency"
import { apitrigger, url } from "../../API/api.services";
import { symbol } from "prop-types";

class Spotpair extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "firstCurrencySymbol",
        text: "Base Currency",
        className: "created_date",
        align: "left",
        sortable: true,
      },
      {
        key: "secondCurrencySymbol",
        text: "Quote Currency",
        className: "created_date",
        align: "left",
        sortable: true,
      },
      // {
      //   key: "toaddress",
      //   text: "To Address",
      //   className: "toaddress",
      //   align: "left",
      //   sortable: true,
      //   width: 200,
      //   cell: (record) => {
      //     if (record.paymentType == "fiat_deposit") {
      //       return "-";
      //     }
      //     if (record.paymentType == "coin_deposit") {
      //       return record.toAddress;
      //     }
      //   },
      // },
      {
        key: "botstatus",
        text: "Bot Status",
        className: "currency",
        align: "left",
        sortable: true,
      },
      // {
      //   key: "paymentType",
      //   text: "Payment Type",
      //   className: "currency",
      //   align: "left",
      //   sortable: true,
      //   cell: (record) => {
      //     return paymentType(record.paymentType);
      //   },
      // },
      // {
      //   key: "amount",
      //   text: "Transfer Amount",
      //   className: "amount",
      //   align: "left",
      //   sortable: true,
      // },
      {
        key: "status",
        text: "Status",
        className: "status",
        align: "left",
        sortable: true,
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() =>
                  this.setState({
                    editSpotpairModal: true,
                    editPair: record,
                  })
                }
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Withdraw",
      no_data_text: "No Records found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "<<",
          previous: "<",
          next: ">",
          last: ">>",
        },
      },
      show_length_menu: false,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };
    this.state = {
      records: [],
      loader: false,
      addSpotpairModal: false,
      editSpotpairModal: false,
    };

    this.state = {
      records: [],
      search: "",
      page: 1,
      limit: 10,
      count: 0,
      loader: false,
      coinFormModal: false,
      fiatFormModal: false,
      depositRecord: {},
    };
    this.fetchDeposit = this.fetchDeposit.bind(this);
    this.refetchDeposit = this.refetchDeposit.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleCloseCoinForm = this.handleCloseCoinForm.bind(this);
    this.handleCloseFiatForm = this.handleCloseFiatForm.bind(this);
    // this.handleCloseAddSpotpair=this.handleCloseAddSpotpair(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.DowloadeCsv = this.DowloadeCsv.bind(this);
    this.DowloadeXls = this.DowloadeXls.bind(this);
    this.fetchCategory = this.fetchCategory.bind(this)
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
      export: "false",
    };
    this.fetchCategory()
    this.fetchDeposit(reqData);
  }
  // componentDidMount(){
  //   this.fetchCategory()
  // }
  async fetchDeposit(reqData) {
    try {
      this.setState({ loader: true });
      const { status, loading, result } = await getPairLists(reqData, 'spot');
      this.setState({ loader: loading });
      if (status == "success") {
        const stablePair = result.pair.find(item => item.firstCurrencySymbol === "USDT" && item.secondCurrencySymbol === "GCP")
        this.setState({ count: result.totalCount, records: result.pair, stablePair: stablePair, allpairs: result.pair });
      }
    } catch (err) { }
  }
  async fetchCategory() {
    try {
      this.setState({ loader: true });
      const { status, loading, result } = await getCurrency();
      this.setState({ loader: loading });
      if (status == "success") {
        // const category = result
        // const secondList = result.filter(item => item.type && item.type === "token")
        const category = result.filter(item => item.type && ["crypto", "fiat","token"].includes(item.type))
        const secondList = result.filter(item => item.type && ["token", "fiat"].includes(item.type))
        category.unshift({ symbol: "Select" })
        secondList.unshift({ symbol: "Select" })
        this.setState({ categoryList: category, fullList: secondList });
      }
    } catch (err) { }
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.fetchDeposit(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  handleCloseCoinForm() {
    this.setState({ coinFormModal: false });
  }
  handleAddSpotpair() {
    this.setState({ addSpotpairModal: true });
  }
  handleCloseAddSpotpair = () => {
    this.setState({ addSpotpairModal: false });
  }
  handleEditSpotpair() {
    this.setState({ editSpotpairModal: true });
  }
  handleCloseEditSpotpair = () => {
    this.setState({ editSpotpairModal: false });
  }

  handleCloseFiatForm() {
    this.setState({ fiatFormModal: false });
  }

  refetchDeposit() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.fetchDeposit(reqData);
  }

  async DowloadeCsv() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
      export: "csv",
    };
    const { status, loading, result } = await getPairLists(reqData, 'spot', 'csv');
  }

  async DowloadeXls() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
      export: "xls",
    };
    const { status, loading, result } = await getPairLists(reqData, 'spot', 'excel');
  }

  async exportPDF() {
    const { records } = this.state;
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
      export: "pdf",
    };
    const { status, loading, result } = await getPairLists(reqData, 'spot', 'pdf');
    if (status == "success") {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(13);

      const title = "Spot pairs";
      const headers = [
        [
          "Base Currency",
          "Quote Currency",
          "Bot Status",
          "Status",
          // "Payment Type",
          // "Transfer Amount",
          // "Status",
        ],
      ];

      const data =
        result &&
        result.pair.length > 0 &&
        result.pair.map((elt) => [
          // moment(elt.createdAt).format(
          //     "DD-MM-YYYY k:mm:s"
          // ),
          elt.firstCurrencySymbol,
          elt.secondCurrencySymbol,
          elt.botstatus,
          elt.status,
        ]);

      let content = {
        startY: 50,
        head: headers,
        body: data,
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("spot_pairs.pdf");
    }
  }

  render() {
    const { loader, count, records, addSpotpairModal, editSpotpairModal, categoryList, fullList, editPair, handleCloseAddSpotpair, stablePair, allPairs } =
      this.state;
    return (
      <div>
        <AddSpotPair
          isShow={addSpotpairModal}
          onHide={this.handleCloseAddSpotpair}
          record={categoryList}
          fullList={fullList}
          stablePair={stablePair}
          allPairs={allPairs}
        // record={editRecord}
        />{" "}
        <EditSpotPair
          isShow={editSpotpairModal}
          onHide={this.handleCloseEditSpotpair}
          // fetchData={this.fetchFaqCategory}
          record={editPair}
          records={categoryList}
          fullList={fullList}
        />
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Spot Pair</h3>

              <div className="d-flex flex-wrap gap-3 align-items-start justify-content-start justify-content-sm-between">
                <div>
                  {records && records.length > 0 ? (
                    <button
                      onClick={this.exportPDF}
                      className="btn btn-info mr-2 mb-2"
                      style={{
                        width: "118px",
                        height: "35px",
                        fontSize: "13px",
                      }}
                    >
                      Download(PDF)
                    </button>
                  ) : (
                    ""
                  )}

                  {records && records.length > 0 ? (
                    // <CSVLink
                    //     data={records}
                    //     filename={"Deposit.csv"}
                    //     className="btn btnTrade1 py-4"
                    // >
                    <button
                      className="btn btn-info mr-2 mb-2"
                      onClick={this.DowloadeCsv}
                      style={{
                        width: "118px",
                        height: "35px",
                        fontSize: "13px",
                      }}
                    >
                      Download(CSV)
                    </button>
                  ) : (
                    // </CSVLink>
                    ""
                  )}

                  {records && records.length > 0 ? (
                    // <CSVLink
                    //     data={records}
                    //     filename={"Deposit.xls"}

                    // >
                    <button
                      className="btn btn-info mb-2"
                      onClick={this.DowloadeXls}
                      style={{
                        width: "118px",
                        height: "35px",
                        fontSize: "13px",
                      }}
                    >
                      Download(XLS)
                    </button>
                  ) : (
                    // </CSVLink>
                    ""
                  )}
                </div>
                <button
                  onClick={() => this.handleAddSpotpair()}
                  className="btn btn-outline-primary"
                  data-toggle="modal"
                  data-target="#add-spot-modal"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-1" />
                  Add Pair
                </button>
              </div>

              <ReactDatatable
                className="table table-bordered table-striped user_management_table"
                config={this.config}
                records={records}
                columns={this.columns}
                dynamic={true}
                total_record={count}
                loading={loader}
                onChange={this.handlePagination}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Spotpair;
