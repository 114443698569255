// import package
import React, { Component } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import { addTourHandler, getTours, updateTourHandler } from "../../../src/actions/walletAction";
import config from "../../config/index";
// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { toastAlert } from "../../lib/toastAlert";

const BackendUrl = config.API_URL

class Tutorial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterRows: [{ filters: "", imageURI: null, description: "", file: "" }], // Add 'file' to each row object
            addTour: true
        };
    }
    componentDidMount() {
        this.fetchdata();
    }
    async fetchdata() {
        const result = await getTours();
        this.setState({ filterRows: result.data });
    }

    addFilters = () => {
        this.setState((prevState) => ({
            filterRows: [...prevState.filterRows, { filters: "", file: null, text: "" }],
        }));
    };

    removeFilters = (index) => {
        this.setState((prevState) => {
            const filterRows = [...prevState.filterRows];
            filterRows.splice(index, 1);
            return { filterRows };
        });
    };

    handleFileChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const fileURL = URL.createObjectURL(file);
            this.setState((prevState) => {
                const filterRows = [...prevState.filterRows];
                filterRows[index].file = fileURL; // Update file only for the specific row
                filterRows[index].imageURI = file
                return { filterRows };
            });
        }
    };

    handleTextChange = (e, index) => {
        const text = e.target.value;
        this.setState((prevState) => {
            const filterRows = [...prevState.filterRows];
            filterRows[index].description = text; // Update text only for the specific row
            return { filterRows };
        });
    };

    handleAddEvent = async (index) => {
        const rowsData = this.state.filterRows;
        const rowData = rowsData[index]
        const formData = new FormData();
        formData.append("description", rowData.description);
        formData.append("image", rowData.imageURI);


        if (!rowData.description) {
            return toastAlert("error", "Description required.", "login")
        }
        if (!rowData.imageURI) {
            return toastAlert("error", "Image required.", "login")
        }

        if (rowData._id) {
            if (!rowData.file) {
                formData.append("imageURI", rowData.imageURI);
            }
            const tour = await updateTourHandler(rowData._id, formData);
            // toastAlert("success", "Tour saved.", "login")
            window.location.reload()
        } else {
            const tour = await addTourHandler(formData);
            // toastAlert("success","Tour saved.", "login")
            window.location.reload()

        }
    }

    removeHandler = async (index) => {
        const rowsData = this.state.filterRows;
        const rowData = rowsData[index]
        const formData = new FormData();
        formData.append("isActive", false);
        const tour = await updateTourHandler(rowData._id, formData);
        // toastAlert("success", "Tour removed.", "login")
        window.location.reload()

    }

    render() {

        const { tours, filterRows } = this.state
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Tutorial</h3>
                            <div className="text-end mb-4">
                                <button className="btn btn-primary" onClick={this.addFilters}>
                                    Add
                                </button>
                            </div>
                            {filterRows && filterRows.map((row, index) => (
                                <div className="row tutorial mb-4" key={index}>
                                    <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="browseinput">
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => this.handleFileChange(e, index)}
                                            />
                                            <button className="btn btn-primary">Upload Image</button>
                                        </div>
                                        {row.file && (
                                            <img
                                                // src={BackendUrl + "/images/currency/" + row.imageURI}
                                                src={row.file}
                                                alt={`Preview ${index}`}
                                                className="previewimgset mt-1"
                                            />
                                        )}
                                        {row.imageURI && !row.file && (
                                            <img
                                                src={BackendUrl + "/images/currency/" + row.imageURI}
                                                // src={row.file}
                                                alt={`Preview ${index}`}
                                                className="previewimgset mt-1"
                                            />
                                        )}
                                    </div>
                                    <div className="col-10 col-sm-7 col-md-6 col-lg-7 col-xl-4 mt-3 mt-sm-0">
                                        <textarea className="form-control"
                                            value={row.description}
                                            onChange={(e) => this.handleTextChange(e, index)}
                                            rows={5} >
                                        </textarea>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-3 mt-sm-0">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => this.removeHandler(index)}
                                        >
                                            -
                                        </button>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-3 mt-lg-0">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => this.handleAddEvent(index)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Tutorial
